export const lightModeColors = {
  white: '#ffffff',
  veryLightGrey: '#f9f9f9',
  lightGrey: '#d3d3d3',
  darkGrey: '#a9a9a9',
  veryDarkGrey: '#121212',
  black: '#000000',
};

export const darkModeColors = {
  white: '#ffffff',
  veryLightGrey: '#f9f9f9',
  lightGrey: '#d3d3d3',
  darkGrey: '#555555',
  veryDarkGrey: '#121212',
  black: '#000000',
};

export const lightModeTheme = {
  colors: lightModeColors,
  name: 'light',
};

export const darkModeTheme = {
  colors: darkModeColors,
  name: 'dark',
};

export const isLightMode = (theme) => theme.name === lightModeTheme.name;

export const isDarkMode = (theme) => theme.name === darkModeTheme.name;

export const colorWithAlpha = (hexCode, opacity) => {
  // eslint-disable-next-line no-underscore-dangle
  const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return hexCode + _opacity.toString(16).toUpperCase();
};
