import './App.css';

import React from 'react';

import {
  BrowserRouter,
  useRoutes,
} from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import NavBar from './components/NavBar';
import Home from './containers/Home';
import {
  darkModeTheme,
  isDarkMode,
  lightModeTheme,
} from './themes';
import Coordinates from './containers/Coordinates';

const Background = styled.div`
  background-color: ${({ theme }) => (isDarkMode(theme) ? theme.colors.veryDarkGrey : theme.colors.veryLightGrey)};
  height: 100vh;
  width: 100vw;
`;

function App() {
  const [theme, setTheme] = React.useState(
    localStorage.getItem('theme') || 'light',
  );

  function AppRoutes() {
    const routes = useRoutes(
      [
        { path: '/', element: <Home /> },
        { path: '/coordinates', element: <Coordinates /> },
      ],
    );
    return routes;
  }

  return (
    <ThemeProvider
      theme={theme === darkModeTheme.name ? darkModeTheme : lightModeTheme}
    >
      <Background theme={theme === darkModeTheme.name ? darkModeTheme : lightModeTheme}>
        <React.StrictMode>
          <BrowserRouter>
            <NavBar theme={theme} setTheme={setTheme} />
            <AppRoutes />
          </BrowserRouter>
        </React.StrictMode>
      </Background>
    </ThemeProvider>
  );
}

export default App;
