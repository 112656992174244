import React from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Dropdown,
  Header,
  Menu,
} from 'semantic-ui-react';
import {
  darkModeTheme,
  lightModeTheme,
} from '../../themes';

// eslint-disable-next-line react/prop-types
function NavBar({ theme, setTheme }) {
  const themeClass = theme === 'dark' ? 'inverted' : '';
  const [dropdown, setDropdown] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const handleDocumentClick = () => {
      setDropdown(false);
    };
    if (dropdown) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [dropdown]);

  return (
    <Menu attached="top" className={themeClass}>
      <Header
        as="h2"
        className={themeClass}
        style={{
          margin: '8px',
          cursor: location.pathname === '/' ? 'initial' : 'pointer',
        }}
        onClick={() => {
          navigate('/');
        }}
      >
        Blind Chess
      </Header>
      <Menu.Menu position="right" className={themeClass}>
        <Dropdown item icon="setting" simple className={themeClass}>
          <Dropdown.Menu className={themeClass}>
            <Dropdown.Item
              className={themeClass}
              onClick={() => {
                if (theme === 'light') {
                  localStorage.setItem('theme', darkModeTheme.name);
                  setTheme(darkModeTheme.name);
                } else {
                  localStorage.setItem('theme', lightModeTheme.name);
                  setTheme(lightModeTheme.name);
                }
              }}
            >
              <span className="text">{`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}

export default NavBar;
