/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Chessboard } from 'react-chessboard';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useTimer } from 'react-timer-hook';
import './style.scss';

const Stages = {
  standby: 0,
  // TODO: Add starting stage as well to give 3 seconds buffer for the player to get ready.
  live: 1,
};

const CoordinateMode = {
  WhiteOnly: 0,
  BlackOnly: 1,
  WhiteOrBlack: 2,
};

const TIME_LIMIT = 30;

function generateNewSquare() {
  return (
    String.fromCharCode('a'.charCodeAt(0) + Math.floor(Math.random() * 8))
    + Math.ceil(Math.random() * 8)
  );
}

function generateNewSquareExcept(except) {
  let newSquare = generateNewSquare();
  while (newSquare === except) {
    newSquare = generateNewSquare();
  }
  return newSquare;
}

function timeAfter(seconds) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + seconds);
  return time;
}

export default function Coordinates() {
  const [selectedSquare, setSelectedSquare] = React.useState('');
  const [actualSquare, setActualSquare] = React.useState(generateNewSquare());
  const [mode, setMode] = React.useState(CoordinateMode.WhiteOnly);
  const [boardOrientation, setBoardOrientation] = React.useState('white');
  const [flickerSquare, setFlickerSquare] = React.useState(false);
  const [stage, setStage] = React.useState(Stages.standby);
  const [score, setScore] = React.useState(0);

  function setBoardOrientationBasedOnMode() {
    switch (mode) {
      case CoordinateMode.WhiteOnly:
        setBoardOrientation('white');
        break;
      case CoordinateMode.BlackOnly:
        setBoardOrientation('black');
        break;
      default:
        setBoardOrientation(Math.ceil(Math.random() * 2) === 1 ? 'white' : 'black');
    }
  }

  React.useEffect(() => {
    setBoardOrientationBasedOnMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  function timerExpireHandler() {
    if (stage === Stages.live) {
      // eslint-disable-next-line no-alert
      alert(`You scored ${score}`);
    }
    setStage(Stages.standby);
  }

  const {
    totalSeconds,
    start,
    restart,
  } = useTimer({
    expiryTimestamp: timeAfter(0),
    onExpire: timerExpireHandler,
  });

  function playButtonHandler() {
    setScore(0);
    setStage(Stages.live);
    restart(timeAfter(TIME_LIMIT));
    start();
  }

  function getCustomSquareStyles() {
    if (stage === Stages.live && selectedSquare && flickerSquare) {
      const customStyle = {};
      customStyle[selectedSquare] = {
        backgroundColor: actualSquare === selectedSquare ? '#00ff00' : '#ff0000',
      };
      return customStyle;
    }
    return null;
  }

  function chessBoardClickHandler(square) {
    if (stage === Stages.live) {
      setScore(score + (square === actualSquare ? 1 : 0));
      setSelectedSquare(square);
      setFlickerSquare(true);
      setTimeout(() => {
        setFlickerSquare(false);
        setBoardOrientationBasedOnMode();
        setActualSquare(generateNewSquareExcept(actualSquare));
      }, 100);
    }
  }

  return (
    <div className="main-container">
      <div className="chess-board-container">
        <div className="top-band">
          {stage === Stages.standby && (
            <Dropdown
              placeholder="Select Mode"
              className="dropdown"
              fluid
              selection
              value={mode}
              onChange={(_, { value }) => setMode(value)}
              options={[
                {
                  key: CoordinateMode.WhiteOnly,
                  value: CoordinateMode.WhiteOnly,
                  text: 'White only',
                },
                {
                  key: CoordinateMode.BlackOnly,
                  value: CoordinateMode.BlackOnly,
                  text: 'Black only',
                },
                {
                  key: CoordinateMode.WhiteOrBlack,
                  value: CoordinateMode.WhiteOrBlack,
                  text: 'White or Black',
                },
              ]}
            />
          )}
          {stage === Stages.live && (
            <>
              <div className="top-band-item">
                <Icon name="star" size="large" />
                <div className="score">{score}</div>
              </div>
              <div className="top-band-item">
                <div className="time">{totalSeconds || TIME_LIMIT}</div>
                <Icon name="clock" size="large" />
              </div>
            </>
          )}
        </div>
        <div className="chess-board">
          <Chessboard
            position="8/8/8/8/8/8/8/8"
            areArrowsAllowed={false}
            showBoardNotation={false}
            // eslint-disable-next-line react/jsx-no-bind
            onSquareClick={chessBoardClickHandler}
            customSquareStyles={getCustomSquareStyles()}
            boardOrientation={boardOrientation}
          />
          <div className={`chess-board-overlay ${stage === Stages.standby ? 'standby' : 'live'}`}>
            {stage === Stages.standby && (
              <div className="play-button" onClick={playButtonHandler}>
                <Icon size="massive" name="play" />
              </div>
            )}
            {stage === Stages.live && (
              <div className={`actual-square ${boardOrientation}`}>{actualSquare}</div>
            )}
          </div>
        </div>
      </div>
      {/* TODO: Add section for rules & leaderboard */}
    </div>
  );
}
