import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Card, Header } from 'semantic-ui-react';

const lessons = [
  {
    title: 'Coordinates',
    description: `An empty chess board without notation will be given and the notation of a square will be given.
      You have to click on the correct square.`,
    route: 'coordinates',
  },
  {
    title: 'Color of square',
    description: 'You will be given the notation of a square, you have to select whether the square is black or white.',
    route: null,
  },
  {
    title: 'Same colors',
    description: `You will be given the notation of two squares.
    You have to select whether both the squares are of same color.`,
    route: null,
  },
  {
    title: 'Route of piece',
    description: `You will be given a piece and the notation of two squares.
    You have to select the square through which the piece can travel from first square to second.`,
    route: null,
  },
  {
    title: 'Common destination',
    description: `You will be given two pieces and the square at which they are.
    You have to select a square where both the pieces can reach in single move.`,
    route: null,
  },
  {
    title: 'Puzzles',
    description: `You will be given a few pieces and the square where it is located in a position.
    You have to find the best move in the position.`,
    route: null,
  },
];

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Card.Group style={{
        margin: 0,
      }}
      >
        {lessons.filter((lesson) => lesson.route).map((lesson) => (
          <Card
            onClick={() => navigate(lesson.route)}
            style={{
              cursor: 'pointer',
            }}
          >
            <Card.Content>
              <Card.Header>{lesson.title}</Card.Header>
              <Card.Description>{lesson.description}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      <Header style={{ padding: '8px' }}>
        Which among these do you want next?
      </Header>
      <Card.Group style={{
        margin: 0,
      }}
      >
        {lessons.filter((lesson) => !lesson.route).map((lesson) => (
          <Card
            // eslint-disable-next-line
            onClick={() => alert('Noted, thank you for your interest!')}
          >
            <Card.Content>
              <Card.Header>{lesson.title}</Card.Header>
              <Card.Description>{lesson.description}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </>
  );
}

export default Home;
